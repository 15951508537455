import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'home',
		component: ()=>import('../views/HomeView.vue')
	},
	{
		path: '/lab',
		name: 'lab',
		component: ()=>import('../views/Lab.vue')
	},
	{
		path: '/career',
		name: 'career',
		component: ()=>import('../views/Career.vue')
	}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition){
		if (to.hash) return {el:to.hash,behavior: 'smooth',top:to.hash==='#portfolio'?150:undefined}
		return {top:0}
	}
})

export default router
