<template>
    <footer>
        <div class="container">
            <a-menu/>
            <h2 class="f-about text-light-20 cl-white up-text"><b>{{getters.toLocal('about_section')}}</b></h2>
            <div class="f-items cl-white text-medium-18 ">
                <template v-for="l,i in links" :key="i">
                    <router-link v-if="l.to" :class="'up-text a-not-href'+(l.class?(' '+l.class):'')" :to="l.to">{{getters.toLocal(l.l)}}</router-link>
                    <a :class="'up-text a-not-href'+(l.class?(' '+l.class):'')" target="_blank" v-else-if="l.href" :href="l.href">{{getters.toLocal(l.l)}}</a>
                    <div @click="l.click" class="up-text" v-else>{{getters.toLocal(l.l)}}</div>
                </template>
            </div>
            <p class="copyright up-text">{{getters.toLocal('copyright')}}</p>
            <p class="copyright-address up-text">{{getters.toLocal('moscow')}}, {{getters.toLocal('russia')}}</p>
        </div>
    </footer>
</template>
<script setup>
import {h} from 'vue';
import { useNotification } from 'naive-ui'
import aMenu from './aMenu.vue';
import {useStore} from 'vuex';

const {getters} = useStore();
const notification = useNotification();
const showN = (t) =>notification.create({title:()=>h('span',{class:'text-light-22 font-light up-text'},getters.toLocal(t)),content:()=>h('span',{class:'a-pay-notif text-light-24'},getters.toLocal('will_coming_soon')),duration:3000});
const links = [/*{l:'FIRST SECTION',to:'/'},*/{l:'ecosystem',to:'/#about'},{l:'development',to:'/lab'},{l:'crypto_pay_nk',to:'/#pay'},{l:'kasatkin_coin',click:()=>showN('kasatkin_coin')},{l:'crypto_core',href:'https://kasatkin-foundation.gitbook.io/crypto-core/'},{l:'pr_materials',href:'https://drive.google.com/file/d/1iMZ1DrG7uhEI_MljAt0vy_7_GAeIVope/view?usp=sharing'},{l:'career',to:'/career',class:'d-none d-m-block'}]
</script>
<style scoped lang="scss">
footer{
    border-top: 1px solid #fff;
    padding: 50px 0 10px 0;
    position: relative;
    z-index: 20;
    :deep(.menu-btn){display: none;}
}
.copyright{
    letter-spacing: -0.04em;
    color: #9B9B9B;
    margin-top: 120px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}
.copyright-address{
    color: #FFF;
    display: block;
    text-align: center;
    font-size: 14px;
}
.f-items{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    line-height: 30px;
    div:not(:last-child){margin-right: 10px;}
    div{cursor: pointer;}
    & > *:not(:last-child){margin-right: 5px;}
}
.f-about{
    font-weight: 700;
    margin:60px 0 25px 0;
}
@media(max-width:992px) {
    .copyright{margin-top: 70px;}
}
@media(max-width:768px) {
    .copyright{margin-top: 50px;}
    .f-about{margin: 40px 0 20px 0;}
    footer{padding-top: 30px;}
}
@media(max-width:576px) {
    .f-items{justify-content: left;}
    .copyright{font-size: 14px;margin-bottom: 0;}
    .copyright-address{font-size: 12px;}
}
</style>