<template>
	<nConfigProvider :theme-overrides="themeOverrides" :theme="darkTheme" :breakpoints="{xs:0,s:576,m:768,l:992,xl:1200,xxl:1500}">
		<!-- <NThemeEditor> -->
        <n-notification-provider placement="top-right">
            <n-card content-style="padding:0;" style="border-radius:0;" :bordered="false">
                <header class="container cl-white">
                    <a-menu header/>
                </header>
                <router-view :key="$route.fullPath"></router-view>
                <footer-vue/>
            </n-card>
        </n-notification-provider>
        <!-- </NThemeEditor> -->
	</nConfigProvider>
</template>
<script setup>
import {onMounted} from "vue";
import {NThemeEditor,darkTheme,NConfigProvider,NNotificationProvider,NCard} from "naive-ui";
import footerVue from '@/components/footerVue.vue'
import aMenu from '@/components/aMenu.vue';
import {useStore} from 'vuex'

const store = useStore()
onMounted(()=>{
    store.commit('resizeBody')
    store.commit('changeLang',localStorage.getItem('lang')||'en')
})
const themeOverrides = {
	common:{
		cardColor:'#0E0E0E',
        primaryColorHover:'#bd69a9',
        primaryColor:'#bd69a9'
	},
    Notification:{
        borderRadius:'15px',
        closeBorderRadius:'50%',
        color:'#434343',
        closeSize:'22px',
        closeIconSize:'14px'
    },
    Menu:{
        itemTextColorHoverHorizontal:'#bd69a9',
        itemTextColorActiveHorizontal:'#9b578b',
        itemTextColorActiveHoverHorizontal:'#9b578b',
        itemTextColorChildActiveHorizontal:'#9b578b',
        itemTextColorChildActiveHoverHorizontal:'#9b578b',
    },
    Input:{
        heightLarge:'70px',
        paddingLarge:'0 40px',
        fontSizeLarge:'22px',
        color:'#202020'
    },
    Upload:{
        draggerBorder:'1px solid #0E0E0E',
        draggerBorderHover:'1px solid #bd69a9',
        borderRadius:'30px'
    }
}
</script>
